<template>
<!--  <div class="bgColor" :style="'height:'+fullHeight+'px;'">-->
<!--    <router-view/>-->
<!--  </div>-->
  <div class="bgColor" style="height: 100%">
    <router-view v-if="isRouterAlive"/>
  </div>

</template>
<script>
export default {
  name:'',
  provide () {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      fullHeight: document.documentElement.clientHeight,
      isRouterAlive: true
    }
  },
  watch: {
    fullHeight (val) {//监控浏览器高度变化
      if(!this.timer) {
        this.fullHeight = val
        this.timer = true
        let that = this
        setTimeout(function (){
          that.timer = false
        },400)
      }

    }
  },
  mounted () {

    this.get_bodyHeight()
  },
  methods :{
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    get_bodyHeight () {//动态获取浏览器高度
      const that = this
      window.onresize = () => {
        return (() => {
          window.fullHeight = document.documentElement.clientHeight
          that.fullHeight = window.fullHeight
        })()
      }
    }
  }
}
</script>
<style lang="scss" scoped>



#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

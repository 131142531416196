import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


import moment from 'moment'//导入文件
Vue.filter('formatDate2', function(value) {
  return moment(value).format('YYYY-MM-DD')
})
Vue.prototype.$moment = moment;//赋值使用


import { Loading } from "element-ui";
Vue.prototype.$loading = Loading.service

import SignCanvas from 'sign-canvas';
Vue.use(SignCanvas);

import './assets/app.css'
import './assets/font/iconfont.css'

// 编辑器
import CKEditor from 'ckeditor4-vue';
Vue.use( CKEditor );

// 预览图片 v-viewer
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})



Vue.config.productionTip = false

import '../src/assets/style/style.scss'
import './utils/rem'
import { setRemInit } from './utils/rem';

setRemInit(); //进行初始化立即运行

/* 引入el-dialog拖拽窗口 */
import directive from './utils/directive';
Vue.use(directive)
/*使用方法：在el-dialog中添加v-draggable*/

import './assets/style/theme/index.css'

import jquery from 'jquery'
Vue.prototype.$ = jquery

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cartCount:0,//购物车商品数量
    StuByUserData:{},//个人信息
    questionType: 1, //试卷类型
    ParFamilyInfo:{},//家长信息
    ParChildren:{},//学生信息
    SubjectList:{},//学科信息
    StuByUserRes:{}
  },
  mutations: {
    saveCartCount(state, count) {
      state.cartCount = count;
    },
    saveStuByUser(state, count) {
      state.StuByUserData = count;
      //console.log(count,'1111')
    },
    changeType(state, count) {
      state.questionType = count;
    },
    saveParFamilyInfo(state, count) {
      state.ParFamilyInfo = count;
    },
    saveParChildren(state, count) {
      state.ParChildren = count;
    },
    saveSubjectList(state, count) {
      state.SubjectList = count;
    },
    saveStuByUserRes(state, count) {
      state.StuByUserRes = count;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({

    storage:window.sessionStorage
  })]
})

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    // 重定向：初始为login界面
    redirect: '/stuLogin'
  },
  {
    path: '/stuLogin',
    name: 'stuLogin',
    component: () => import('@/views/user/stuLogin.vue'),
    meta: {
      title: '学生登录',
      requireAuth:true
    }
  },
  {
    path: '/parLogin',
    name: 'parLogin',
    component: () => import('@/views/user/parLogin.vue'),
    meta: {
      title: '家长登录',
      requireAuth:false
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/user/register.vue'),
    meta: {
      title: '注册',
      requireAuth:false
    }
  },
  {
    path: '/Parregister',
    name: 'Parregister',
    component: () => import('@/views/user/Parregister.vue'),
    meta: {
      title: '家长注册',
      requireAuth:false
    }
  },
  {
    path: '/revise',
    name: 'revise',
    component: () => import('@/views/user/revise.vue'),
    meta: {
      title: '修改资料',
      requireAuth:false
    }
  },
  {
    path: '/stuChangePassword',
    name: 'stuChangePassword',
    component: () => import('@/views/user/stuChangePassword.vue'),
    meta: {
      title: '学生修改密码',
      requireAuth:true
    }
  },
  {
    path: '/parChangePassword',
    name: 'parChangePassword',
    component: () => import('@/views/user/parChangePassword.vue'),
    meta: {
      title: '家长修改密码',
      requireAuth:true
    }
  },
  {
    path: '/ParRevise',
    name: 'ParRevise',
    component: () => import('@/views/user/ParRevise.vue'),
    meta: {
      title: '家长个人信息',
      requireAuth:false
    }
  },
  {
    path: '/maina',
    name: 'maina',
    component: () => import('@/views/maina'),
    children: [
      {
        path: 'caseHome',
        name: 'caseHome',
        component: () => import('@/views/customer/caseHome.vue'),
        meta: {
          title: '学生首页',
          requireAuth:true
        }
      },
      {
        path: 'parentCaseHome',
        name: 'parentCaseHome',
        component: () => import('@/views/parent/caseHome.vue'),
        meta: {
          title: '家长首页',
          requireAuth:true
        }
      },
    ]
  },
  {
    path: '/mainb',
    name: 'mainb',
    component: () => import('@/views/mainb'),
    children: [
      //试用
      {
        path: 'tourCourseware',
        name: 'tourCourseware',
        component: () => import('@/views/tourist/courseware.vue'),
        meta: {
          title: '图书列表',
          requireAuth:false
        }
      },{
        path: 'tourCatalogue',
        name: 'tourCatalogue',
        component: () => import('@/views/tourist/catalogue.vue'),
        meta: {
          title: '学历案目录',
          requireAuth:false
        }
      },
      {
        path: 'tourDetails',
        name: 'tourDetails',
        component: () => import('@/views/tourist/details.vue'),
        meta: {
          title: '学历案详情',
          requireAuth:false
        }
      },
      //非试用 Recharge
      {
        path: 'recharge',
        name: 'recharge',
        component: () => import('@/views/customer/recharge.vue'),
        meta: {
          title: '充值',
          requireAuth:true
        }
      },

      {
        path: 'alipay',
        name: 'alipay',
        component: () => import('@/views/customer/alipay.vue'),
        meta: {
          title: '支付宝-alipay',
          requireAuth:true
        }
      },
      {
        path: 'cart',
        name: 'cart',
        component: () => import('@/views/customer/cart.vue'),
        meta: {
          title: '购物车',
          requireAuth:true
        }
      },
      {
        path: 'order',
        name: 'order',
        component: () => import('@/views/customer/order.vue'),
        meta: {
          title: '订单',
          requireAuth:true
        }
      },
      {
        path: 'courseware',
        name: 'courseware',
        component: () => import('@/views/customer/courseware.vue'),
        meta: {
          title: '图书列表',
          requireAuth:false
        }
      },
      {
        path: 'catalogue',
        name: 'catalogue',
        component: () => import('@/views/customer/catalogue.vue'),
        meta: {
          title: '学历案目录',
          requireAuth:true
        }
      },
      {
        path: 'details',
        name: 'details',
        component: () => import('@/views/customer/details.vue'),
        meta: {
          title: '学历案详情',
          requireAuth:true
        }
      },
      {
        path: 'unit',
        name: 'unit',
        component: () => import('@/views/customer/unit.vue'),
        meta: {
          title: '单元实战演练',
          requireAuth:true
        }
      },
      {
        path: 'UnitTestQuestions',
        name: 'UnitTestQuestions',
        component: () => import('@/views/customer/UnitTestQuestions.vue'),
        meta: {
          title: '单元实战演练',
          requireAuth:true
        }
      },
      {
        path: 'journal',
        name: 'journal',
        component: () => import('@/views/customer/journal.vue'),
        meta: {
          title: '学历案日志',
          requireAuth:true
        }
      },
      {
        path: 'timeline',
        name: 'timeline',
        component: () => import('@/views/customer/timeline.vue'),
        meta: {
          title: '学习时间轴',
          requireAuth:true
        }
      },
      {
        path: 'evaluation',
        name: 'evaluation',
        component: () => import('@/views/customer/evaluation.vue'),
        meta: {
          title: '知识内容测评',
          requireAuth:true
        }
      },
      {
        path: 'knowledgeContent',
        name: 'knowledgeContent',
        component: () => import('@/views/customer/knowledgeContent.vue'),
        meta: {
          title: '知识内容',
          requireAuth:true
        }
      },
      {
        path: 'tentKnowledgeList',
        name: 'tentKnowledgeList',
        component: () => import('@/views/customer/tentKnowledgeList.vue'),
        meta: {
          title: '知识内容',
          requireAuth:true
        }
      },
      {
        path: 'correctionResults',
        name: 'correctionResults',
        component: () => import('@/views/customer/correctionResults.vue'),
        meta: {
          title: '批改结果',
          requireAuth:true
        }
      },
      {
        path: 'wrongQuestion',
        name: 'wrongQuestion',
        component: () => import('@/views/customer/wrongQuestion.vue'),
        meta: {
          title: '错题本',
          requireAuth:true
        }
      },
      {
        path: 'wrongQuestionDetails',
        name: 'wrongQuestionDetails',
        component: () => import('@/views/customer/wrongQuestionDetails.vue'),
        meta: {
          title: '错题本错题详情',
          requireAuth:true
        }
      },
      // 家长
      {
        path: 'parHomeWorkComplete',
        name: 'parHomeWorkComplete',
        component: () => import('@/views/parent/parHomeWorkComplete.vue'),
        meta: {
          title: '家长-作业/考试完成情况',
          requireAuth:true
        }
      },
      {
        path: 'parHomeaChievComplete',
        name: 'parHomeaChievComplete',
        component: () => import('@/views/parent/parHomeaChievComplete.vue'),
        meta: {
          title: '家长-成绩统计情况',
          requireAuth:true
        }
      },
      {
        path: 'KnowledgeMastery',
        name: 'KnowledgeMastery',
        component: () => import('@/views/parent/KnowledgeMastery.vue'),
        meta: {
          title: '家长-知识点掌握情况',
          requireAuth:true
        }
      },
      {
        path: 'parunit',
        name: 'parunit',
        component: () => import('@/views/parent/unit.vue'),
        meta: {
          title: '家长-知识点掌握情况-批改结果',
          requireAuth:true
        }
      },
      {
        path: 'KnowledgeMastery',
        name: 'KnowledgeMastery',
        component: () => import('@/views/parent/KnowledgeMastery.vue'),
        meta: {
          title: '家长-知识掌握情况',
          requireAuth:true
        }
      },
      {
        path: 'KnowledgeMasteryDetail',
        name: 'KnowledgeMasteryDetail',
        component: () => import('@/views/parent/KnowledgeMasteryDetail.vue'),
        meta: {
          title: '家长-知识掌握情况',
          requireAuth:true
        }
      },

      {
        path: 'personalGrowth',
        name: 'personalGrowth',
        component: () => import('@/views/parent/personalGrowth.vue'),
        meta: {
          title: '家长-个人成长轨迹',
          requireAuth:true
        }
      },
      {
        path: 'orderQuery',
        name: 'orderQuery',
        component: () => import('@/views/parent/orderQuery.vue'),
        meta: {
          title: '家长-订单',
          requireAuth:true
        }
      },







    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
